







































import coreApiClient from "@/services/apis/coreApiClient";
import Vue from "vue";
export default Vue.extend({
  props: ["item"],
  data() {
    return {
      targets: null
    };
  },
  computed: {
    items() {
      return this.item.items.filter(x => x.targetType === "team");
    },
    labels() {
      const arr = new Set();
      for (const item of this.items) {
        arr.add(item.label);
      }
      return Array.from(arr);
    }
  },
  methods: {
    value(label, targetId) {
      const itemItem = this.items.find(
        i => i.label === label && i.targetId === targetId
      );
      return itemItem && itemItem.value;
    },
    totalLabelValue(label) {
      return this.items
        .filter(x => x.label === label)
        .reduce((sum, item) => {
          if (item.value) {
            sum += item.value;
          }
          return sum;
        }, 0);
    },
    totalTargetValue(targetId) {
      return this.items
        .filter(x => x.targetId === targetId)
        .reduce((sum, item) => {
          if (item.value) {
            sum += item.value;
          }
          return sum;
        }, 0);
    },
    totalValue() {
      return this.items.reduce((sum, item) => {
        if (item.value) {
          sum += item.value;
        }
        return sum;
      }, 0);
    }
  },
  async created() {
    const { items } = await coreApiClient.call("teams", "findAll", {
      limit: -1
    });
    this.targets = items;
  }
});
