

























import Vue from "vue";
export default Vue.extend({
  props: ["item"],
  computed: {
    items() {
      return this.item.items.filter(x => x.targetType === "company");
    },
    totalValue() {
      return this.items.reduce((sum, item) => {
        if (item.value) {
          sum += item.value;
        }
        return sum;
      }, 0);
    }
  }
});
