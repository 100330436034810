






































import coreApiClient from "@/services/apis/coreApiClient";
import Vue from "vue";
export default Vue.extend({
  props: ["item"],
  data() {
    return {
      targets: null,
    };
  },
  computed: {
    items() {
      return this.item.items.filter((x) => x.targetType === "user");
    },
    totalValue() {
      return this.items.reduce((sum, item) => {
        if (item.value) {
          sum += item.value;
        }
        return sum;
      }, 0);
    },
  },
  methods: {
    email(id) {
      if (!this.targets) {
        return;
      }
      const target = this.targets.find((x) => x._id === id);
      if (!target) {
        return;
      }
      return target.email;
    },
    totalUserItemValue(key) {
      return this.item?.userItems?.reduce(
        (total, userItem) => total + (userItem?.[key] || 0),
        0
      );
    },
    userItemValue(id, key) {
      const userItem = this.item?.userItems?.find((x) => x.userId === id);
      return userItem?.[key] || 0;
    },
  },
  async created() {
    const { items } = await coreApiClient.call("users", "findAll", {
      limit: -1,
    });
    this.targets = items;
  },
});
